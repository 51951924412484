@import './_breakpoints.css';
@import './_constants.css';

.HOHomeSalesMapView {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  user-select: none;
  position: relative;
}

.MapViewNullState {
  height: unset;
}

.MobileButtonsPositioner {
  position: absolute;
  z-index: 2;
  bottom: 38px;
  right: -2px;
  transform: translateY(0);
  transition: transform 200ms;
}

.MobileButtonsPositioner.positionedHigher {
  transform: translateY(-98px);
}

.MobileButtons {
  display: flex;
  flex-direction: column;
}

.MobileButton {
  color: inherit;
  background-color: white;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
  min-width: var(--mobileControlButtonWidth);
  font-size: 9px;
  display: flex;
  align-items: center;
  padding: 30px 0 8px;
  justify-content: center;
  font-weight: 600;
  max-height: initial;
  z-index: 4;
  margin-top: 16px;
  position: relative;

  &:focus {
    outline: auto;
  }

  &:hover {
    color: inherit;
    /* over-ride default button style */
    transform: translateY(0);
    background-color: white;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
  }

  & .FlatButtonLabel {
    font-size: 9px;
  }
}

.MobileButtonIcon {
  position: absolute;
  bottom: 27px;
  right: 17px;
}

.MobileButtonIcon,
.MobileButtonIcon-svg {
  height: 13px;
  width: auto;
}

.MobileButtonIcon.Spinner,
.MobileButtonIcon-svg.Spinner-svg {
  height: 26px;
  width: auto;
}

.MainContent {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  position: relative;
}

.MapSection {
  flex: 1;
  height: 100%;
  position: relative;
  /* Required since the .MapContainer can't have overflow due to controls placed outside of it */
  overflow: hidden;
  transition: filter 400ms;
  /* Slightly off-white to show separation during SSR */
  background: #f9f9f9;
  /* Setting to match the map's baselayer background color so that you don't see a white area when
   * the bottom nav temporarily hides, like when the mobile filters are open */
  background-color: #faf5ec;

  & :global .mapboxgl-ctrl-bottom-right {
    right: 0;
    bottom: -2px;
    /* Above the No MLS map notification */
    z-index: 3;
  }

  /* Zoom controls */
  & :global .mapboxgl-ctrl-top-right {
    top: initial;
    bottom: 40px;
    z-index: 1;
  }

  & .MapNotification {
    justify-content: flex-start;
  }

  & .LayerItemsControl {
    left: 10px;
    justify-content: flex-start;
  }
}

@media (--small-screen) {
  .MainContent {
    position: relative;
  }

  .MapSection {
    flex: none;
    width: 100vw;

    & .LayerItemsControl {
      z-index: 3;
    }

    & .MapMarkerPopup {
      max-width: calc(100vw - 40px);
      width: 220px;

      & .ThumbnailWrapper,
      & .PropertyCard {
        max-width: calc(100vw - 40px);
        width: 220px;
        overflow: hidden;
      }
    }

    & :global .mapboxgl-ctrl-attrib {
      font-size: 12px;
      line-height: 20px;
    }

    & .MapNotification.MapNotificationPositionedHigher {
      bottom: 135px;
    }

    & .MapNotification {
      justify-content: center;
    }
  }
}

@media (--tablet-portrait-screen) {
  .MapSection {
    & :global .mapboxgl-ctrl-top-right {
      top: 65px;
    }

    & .LayerGroupsControlInner {
      width: 100%;
      justify-content: space-around;
    }

    & .LayerItemsControl {
      max-width: 100%;
      bottom: 0;

      & .LayerItemsControlInner {
        /* hiding mapbox info icon */
        z-index: 3;
        width: 100%;
        /* Extra vertical room for the X icon */
        padding: 30px 10px 20px;
        border-radius: 0;

        & .LayerItemsControlMarkerToggleColumnMonochrome {
          padding-right: 0;
        }
      }
    }
  }
}
