@value text-color from './_colors.css';

.SignUpOrLoginLink {
  font-family: 'Avenir W01', Avenir, Helvetica, sans-serif;
  text-align: center;
  font-size: 14px;
  margin-top: 26px;
  color: text-color;
  display: flex;
  justify-content: center;

  & .FlatButton {
    padding-top: 0;
    text-decoration: underline;
  }

  & svg {
    transform: rotate(180deg);
  }
}
