/* stylelint-disable-next-line */
@value sold-property-card-height: 130px;

.CompSalesCard {
  background: white;
  padding: 20px;
  border-radius: 14px;
  position: fixed;
  /* Above map, map control buttons, and Header */
  z-index: 101;
  width: 80%;
  margin: 5% auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: sold-property-card-height;
  display: flex;
  align-items: center;
}

.CompSalesCardTopDetail {
  margin-top: 0 !important;
}
