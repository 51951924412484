@import './_breakpoints.css';
@import './_constants.css';
@value text-color, text-color-light from './_colors.css';
@value rounded-border from './_fontsAnimations.css';

.CompSalesCard {
  min-height: var(--homeownerCompSalesCardHeight);
  max-height: var(--homeownerCompSalesCardHeight);
  max-width: 330px;
  cursor: pointer;
  margin-top: 24px;

  & .ValueNotAvailablePlaceholder {
    color: text-color-light;
    font-weight: 400;
    font-size: 12px;
  }

  & .Clickable {
    cursor: pointer;
  }
}

.CompSalesCardLink {
  display: block;
  text-decoration: none;
}

.CompSalesCardDetailWrapper {
  display: flex;
}

.CompSalesCardTopDetail {
  display: flex;
  /* limit width here in case varrying font-sizes cause the CompSalesCards to line break */
  max-width: 285px;
}

.CompSalesPropertyPhoto {
  width: 150px;
  height: 90px;
  border-radius: rounded-border;
  display: inline-block;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.CompSalesCardSoldDetail {
  display: inline-block;
  padding-left: 15px;
  vertical-align: top;
}

.CompSalesCardSoldDate {
  font-weight: 900;
  line-height: 19px;
  font-size: 14px;
  color: #3d3d3d;

  &.CompSalesCardSoldDateSmall {
    font-weight: 300;
    line-height: 25px;
    font-size: 12px;
  }
}

.CompSalesCardPrice {
  font-size: 22px;
  font-weight: 900;
  color: text-color;
  line-height: 30px;
}

.CompSalesCardListPrice {
  font-size: 12px;
  font-weight: 900;
}

.CompSalesCardInnerContainer {
  text-decoration: none;
  width: 100%;
}

.CompSalesCardInner {
  border-radius: rounded-border;
  background-color: #fdfcfc;
  overflow: hidden;
  box-shadow: 0 15px 14px 0 rgb(208 206 206 / 50%);
  transition:
    box-shadow 0.2s,
    transform 0.2s;
  padding: 25px;
  box-sizing: border-box;
}

.CompSalesCardAddress {
  color: text-color;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  & a {
    color: text-color !important;
    text-decoration: none !important;
  }

  font-size: 12px;
  font-weight: 300;
  margin: 10px 0 5px;
  line-height: 1.1rem;
}

.CompSalesCardBasicInfo {
  color: text-color;
  display: flex;
  font-size: 12px;
  font-weight: 300;

  & .VerticalSeparator {
    margin: 0 5px;
    border-right: 1px solid #d7d7d7;
  }
}

.MlsLogoOverlay {
  position: absolute;
  height: 16px;
  width: 32px;
  bottom: 4px;
  left: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (--small-screen) {
  .CompSalesCard {
    min-height: auto;
    height: auto;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
  }

  .CompSalesCardPropertyDetails {
    margin-left: 4px;
  }

  .CompSalesCardInner {
    width: 100%;
    border: none;
    border-radius: 0;
    margin-right: 0;
    box-shadow: none;
    transition: none;
    padding: 0;
    background: none;
  }

  .CompSalesCardTopDetail {
    margin-top: 24px;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .CompSalesPropertyPhoto {
    height: 75px;
    width: 143px;
    background-size: cover;
  }

  .CompSalesCardPrice,
  .CompSalesCardSoldDate {
    font-size: 12px;
    line-height: 17px;
    padding: 0;
    margin: 0;
    font-weight: 600;
  }
}

@media (--tablet-screen) {
  .CompSalesCardInner {
    width: 320px;
  }
}

@media (--large-screen) {
  .CompSalesCardInner {
    width: var(--homeownerCompSalesCardWidth);
  }
}
