.ListingStatusSelector {
  display: flex;
  justify-content: center;
  height: 50px;

  & .SelectButton {
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    height: 28px;
    margin: auto 0;
    border-bottom: 1px solid white;
  }

  & .ActiveSelectButton {
    font-weight: 800;
    font-size: 13.6px;
  }

  & .Divider {
    background: #d8d8d8;
    width: 1px;
    height: 25px;
    margin: auto 20px;
  }

  & .CourtesyText {
    color: #4a4a4a;
    font-size: 9px;
    margin: 10px 30px auto auto;
  }
}
