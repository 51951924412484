.HOHomeSalesNullState {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;

  & .NullStateText {
    font-size: 24px;
    margin: auto auto auto 0;
    padding: 20px;
  }

  & .NullStateTextOneColumnSize {
    font-size: 14px;
    margin: auto;
  }

  & .NullStateImage {
    width: 650px;
    height: 450px;
    margin: auto;
  }

  & .NullStateImage-svg {
    width: 100%;
    height: auto;
  }

  & .NullStateImageOneColumnSize {
    width: 100%;
    height: 250px;
    margin: auto;
  }
}
